import { fromISOToString } from "@/utils/date-time";
import { InputReportsEnum, SqlOperator } from "@generated/graphql";
import { DateTime } from "luxon";

export const REPORTS_HEADERS = [
  { value: "id", text: "ID", sortable: false, width: 100 },
  {
    value: "title",
    text: "Название",
    hidden: false,
    sortable: false,
  },
  {
    value: "comment",
    text: "Описание",
    hidden: false,
    sortable: false,
  },
  {
    value: "status",
    text: "Статус",
    width: 200,
    hidden: false,
    sortable: false,
  },
  {
    value: "created_at",
    text: "Дата создания",
    hidden: false,
    sortable: false,
  },
  {
    value: "_actions",
    text: "",
    sortable: false,
  },
];

export const REPORT_FILTERS_CONFIG = {
  id: {
    column: InputReportsEnum.Id,
    operator: SqlOperator.Eq,
  },
  title: {
    column: InputReportsEnum.Title,
    operator: SqlOperator.Ilike,
    changeValue: (val) => `%${val}%`,
  },
  comment: {
    column: InputReportsEnum.Comment,
    operator: SqlOperator.Ilike,
    changeValue: (val) => `%${val}%`,
  },
  status: {
    column: InputReportsEnum.Status,
    operator: SqlOperator.In,
    changeValue: (val) => val.map((p) => p.toLowerCase()),
  },
  created_at: {
    column: InputReportsEnum.CreatedAt,
    operator: SqlOperator.Date,
    changeValue: (val) =>
      DateTime.fromISO(val).toISO({
        suppressMilliseconds: true,
        includeOffset: true,
      }),
  },
};

export const HEADERS_TYPE_FIELDS = {
  comment: "comment",
  completed_at: "completed_at",
  created_at: "created_at",
  id: "id",
  path: "path",
  status: "status",
  title: "title",
  type: "type",
  url: "url",
};

export const REPORTS_STATUS = [
  { value: "Completed", text: "Сформирован" },
  { value: "Created", text: "Ожидает формирования" },
  { value: "Failed", text: "Ошибка" },
  { value: "Progress", text: "Формируется" },
  { value: "Creating", text: "Добавляется" },
];

/**
 * Возвращает форматированный статус отчета
 * @param {string} reportStatus
 * @returns string
 */
function getReportStatus(reportStatus) {
  const statusReport = REPORTS_STATUS.find(
    (status) => status.value.toLowerCase() === reportStatus.toLowerCase()
  );
  return statusReport.text;
}

/**
 * Возвращает список отформатированных отчетов
 * @param {Report[]} reports
 * @returns array
 */
export function convertedReports(reports) {
  return reports.map((report) => {
    return {
      comment: report.comment,
      completed_at: fromISOToString(report.completed_at, "DD"),
      created_at: fromISOToString(report.created_at, "DD"),
      id: report.id,
      path: report.path,
      status: getReportStatus(report.status),
      title: report.title,
      type: report.type,
      url: report.url,
    };
  });
}

/**
 * Возвращает название отчета
 * @param {string} eventId
 * @returns string
 */
export function getNmoReportTitle(eventId) {
  if (eventId) {
    return `Отчёт по форме НМО по мероприятию ${eventId}`;
  }
  return "Отчёт по форме НМО по мероприятию";
}

/**
 * Возвращает название отчета
 * @param {string} eventId
 * @returns string
 */
export function getPartnerReportTitle(eventId) {
  if (eventId) {
    return `Отчёт для партнёра по мероприятию ${eventId}`;
  }
  return "Отчёт для партнёра по мероприятию";
}
