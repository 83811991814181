




































































import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";
import { UsersList } from "@/graphql/queries/User.graphql";
import {
  InputUsersEnum,
  SqlOperator,
  User,
  UserPaginator,
  UsersListQueryVariables,
} from "@/generated/graphql";
import Card from "@components/page/Card.vue";

@Component({
  components: { Card },
  apollo: {
    users: {
      query: UsersList,
      variables(): UsersListQueryVariables {
        return {
          page: 1,
        };
      },
      skip: true,
    },
  },
})
export default class UsersAutocomplete extends Vue {
  @Prop({ type: Array }) selectedItems!: User;
  @VModel({ type: [Object, Array] }) model!: User | User[];

  protected selected: User | User[] | null = null;
  protected searchValue = "";
  protected usersPickerVisible = false;
  protected users: UserPaginator = {
    paginatorInfo: {
      count: 0,
      currentPage: 1,
      perPage: 10,
      hasMorePages: true,
      lastPage: 0,
      total: 0,
    },
    data: [],
  };

  protected fetchMoreUsers(): void {
    this.$apollo.queries.users.fetchMore({
      //@ts-ignore
      variables: {
        ...this.fetchVariables,
        page: this.users.paginatorInfo.currentPage++,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          users: {
            __typename: fetchMoreResult.users.__typename,
            paginatorInfo: fetchMoreResult.users.paginatorInfo,
            data: [...this.users.data, ...fetchMoreResult.users.data],
          },
        };
      },
    });
  }

  protected get fetchVariables(): UsersListQueryVariables {
    return {
      page: this.users.paginatorInfo.currentPage,
      where: {
        OR: [
          {
            column: InputUsersEnum.FamilyName,
            value: `%${this.searchValue}%`,
            operator: SqlOperator.Ilike,
          },
          {
            column: InputUsersEnum.SecondName,
            value: `%${this.searchValue}%`,
            operator: SqlOperator.Ilike,
          },
          {
            column: InputUsersEnum.FirstName,
            value: `%${this.searchValue}%`,
            operator: SqlOperator.Ilike,
          },
        ],
      },
    } as UsersListQueryVariables;
  }

  @Debounce(500)
  protected search(): void {
    this.$apollo.queries.users.refetch(this.fetchVariables);
  }
}
