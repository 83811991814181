
























































































































































































import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import {
  City,
  DoctorAssociation,
  DoctorPosition,
  DoctorSpecialty,
  DoctorWorkplace,
  HospitalTypeEnum,
  ParticipantStatusEnum,
  Role,
  User,
} from "@/generated/graphql";

import Card from "@components/page/Card.vue";
import UsersAutocomplete from "@/components/widgets/commons/Inputs/UsersAutocomplete.vue";
import UsersTable from "@/components/parts/tables/UsersTable.vue";
import RolesAutocomplete from "@widgets/commons/Inputs/RolesAutocomplete.vue";
import SpecialtiesAutocomplete from "@widgets/commons/Inputs/SpecialtiesAutocomplete.vue";
import WorkplacesAutocomplete from "@widgets/commons/Inputs/WorkplacesAutocomplete.vue";
import PositionsAutocomplete from "@widgets/commons/Inputs/PositionsAutocomplete.vue";
import AssociationsAutocomplete from "@widgets/commons/Inputs/AssociationsAutocomplete.vue";
import ServerCityAutocomplete from "@widgets/commons/Inputs/ServerCityAutocomplete.vue";
import { hospitalTypes } from "@/core/static/dict";
import {
  createUsersNmoReport,
  createUsersPartnerReport,
} from "@/graphql/queries/Reports.graphql";
import { MutationCreateNmoUsersReportWhereColumn } from "@/generated/graphql";
import { getPartnerReportTitle, getNmoReportTitle } from "@/domain/report";

const initialFilters: {
  roles: Role[];
  specialties: DoctorSpecialty["id"][];
  cities: City[];
} = {
  roles: [],
  specialties: [],
  cities: [],
};

const initialAdditionalFilters: {
  positions: DoctorPosition["id"][];
  workplaces: DoctorWorkplace["id"][];
  hospitalType: HospitalTypeEnum | null;
  associations: DoctorAssociation["id"][];
} = {
  hospitalType: null,
  positions: [],
  workplaces: [],
  associations: [],
};

function contains(searchIn: unknown[], arr: unknown[]): boolean {
  return searchIn.some((item) => arr.indexOf(item) >= 0);
}

@Component({
  components: {
    Card,
    UsersTable,
    UsersAutocomplete,
    RolesAutocomplete,
    SpecialtiesAutocomplete,
    WorkplacesAutocomplete,
    PositionsAutocomplete,
    AssociationsAutocomplete,
    ServerCityAutocomplete,
  },
})
export default class EventParticipants extends Vue {
  @VModel({ type: Array, default: () => [] }) items!: User[];
  @Prop({ required: true, default: "" }) eventId!: string;

  protected additionalFiltersVisible = false;
  protected readonly initialFilters = initialFilters;
  protected filters = { ...initialFilters };

  protected readonly initialAdditionalFilters = initialAdditionalFilters;
  protected additionalFilters = { ...initialAdditionalFilters };

  protected readonly hospitalTypes = hospitalTypes;

  protected selected: User[] = [];
  isReportLoading: boolean = false;
  isReportPartnerLoading: boolean = false;
  isReportCreated: boolean = false;

  get canUserReport() {
    return this.$store.getters["session/isAllow"]("CREATE_NMO_USERS_REPORT");
  }

  get canPartnerReport() {
    return this.$store.getters["session/isAllow"](
      "CREATE_EVENT_PARTICIPANTS_PARTNER_REPORT"
    );
  }

  protected spliceUsers(): void {
    if (!this.selected.length) return;
    this.selected.forEach((item) => {
      this.items.splice(this.items.indexOf(item), 1);
    });

    this.selected = [];
  }

  protected addParticipants(users: User[]): void {
    console.log([
      ...this.items,
      ...users.map((user) => {
        return {
          ...user,
          pivot: {
            participant_status: ParticipantStatusEnum.Registered,
          },
        };
      }),
    ]);
    this.items = [
      ...this.items,
      ...users.map((user) => {
        return {
          ...user,
          pivot: {
            participant_status: ParticipantStatusEnum.Registered,
          },
        };
      }),
    ];
  }

  protected get filteredItems(): User[] {
    const filter = this.filters;
    const additionalFilter = this.additionalFilters;
    const conditions: CallableFunction[] = [];

    if (filter.roles) conditions.push(this.filterRoles);
    if (filter.specialties) conditions.push(this.filterSpecialties);
    if (filter.cities) conditions.push(this.filterCities);

    if (additionalFilter.positions) conditions.push(this.filterPositions);
    if (additionalFilter.associations) conditions.push(this.filterAssociations);
    if (additionalFilter.workplaces) conditions.push(this.filterWorkplaces);
    if (additionalFilter.hospitalType) conditions.push(this.filterHospitalType);

    if (conditions.length > 0) {
      return this.items.filter((item) =>
        conditions.every((cond) => cond(item))
      );
    } else return this.items;
  }

  private filterRoles(item: User): boolean {
    if (!this.filters.roles.length) return true;
    return contains(
      item.roles?.map((r) => r.id) || [],
      this.filters.roles.map((r) => r.id)
    );
  }

  private filterSpecialties(item: User): boolean {
    if (!this.filters.specialties.length) return true;
    return contains(
      item.doctorProfile?.specialties?.map((i) => i.id) || [],
      this.filters.specialties
    );
  }
  private filterWorkplaces(item: User): boolean {
    if (!this.additionalFilters.workplaces.length) return true;
    return contains(
      item.doctorProfile?.workplaces?.map((i) => i.id) || [],
      this.additionalFilters.workplaces
    );
  }
  private filterPositions(item: User): boolean {
    if (!this.additionalFilters.positions.length) return true;
    return contains(
      item.doctorProfile?.positions?.map((i) => i.id) || [],
      this.additionalFilters.positions
    );
  }
  private filterAssociations(item: User): boolean {
    if (!this.additionalFilters.associations.length) return true;
    return contains(
      item.doctorProfile?.associations?.map((i) => i.id) || [],
      this.additionalFilters.associations
    );
  }
  private filterHospitalType(item: User): boolean {
    if (!this.additionalFilters.hospitalType) return true;
    return !!item.doctorProfile?.workplaces.find(
      (i) => i.hospital_type === this.additionalFilters.hospitalType
    );
  }

  private filterCities(item: User): boolean {
    if (!this.filters.cities.length) return true;
    return item.city
      ? this.filters.cities.map((city) => city.id).includes(item.city?.id)
      : false;
  }

  async createNmoReport() {
    try {
      this.isReportLoading = true;
      this.isReportCreated = false;
      await this.$apollo.mutate({
        mutation: createUsersNmoReport,
        variables: {
          input: {
            user: { connect: this.$store.state.session.me.id },
            title: getNmoReportTitle(this.eventId),
          },
          where: {
            column: MutationCreateNmoUsersReportWhereColumn.EventId,
            value: this.eventId,
          },
        },
      });
      this.isReportCreated = true;
    } catch (e) {
      console.error(e);
    } finally {
      this.isReportLoading = false;
    }
  }

  async createPartnerReport() {
    try {
      this.isReportPartnerLoading = true;
      this.isReportCreated = false;
      await this.$apollo.mutate({
        mutation: createUsersPartnerReport,
        variables: {
          input: {
            user: { connect: this.$store.state.session.me.id },
            title: getPartnerReportTitle(this.eventId),
          },
          where: {
            column: MutationCreateNmoUsersReportWhereColumn.EventId,
            value: this.eventId,
          },
        },
      });
      this.isReportCreated = true;
    } catch (e) {
      console.error(e);
    } finally {
      this.isReportPartnerLoading = false;
    }
  }
}
